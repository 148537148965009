import React from 'react';
import Footer from '../components/footer';
import Wrapper from '../components/Wrapper';
import Seo from '../shared/seo';
import Acnavbar from '../components/Acnavbar';
import bugsworkfavicon from '../assets/images/favicon.png';
import Hero from '../components/Hero';
import VisualData from '../json/visualpage.json';
import Feature from '../components/Feature';
import Integration from '../components/Integration';
import Efficiency from '../components/Efficiency';

const VisualFeedback = () => {
  return (
    <Wrapper>
      <Seo
        title="bugs.work"
        description="Move fast, stay aligned, and build better - together"
      />

      <Acnavbar
        home={true}
        pathname={'visual-feedback'}
        logo={bugsworkfavicon}
        logoLogic={false}
      />
      <Hero
        isScrollAnimationPresent={true}
        headerText="Reporting bugs doesn’t have to be tedious anymore!"
        subHeading="bugs.work - a new era of visual bug reporting, where testing is quick, easy, and efficient. 
                    We revolutionise the way you track and resolve issues, allowing you to capture and 
                    communicate crucial information with just a few clicks. Free up your team to focus on 
                    what matters, and let us handle the complexities of bug reporting."
      />

      {VisualData.map((visual) => (
        <Feature
          img={visual.img}
          headerText={visual?.headerText}
          bulletPoints={visual?.bulletPoints}
          imagePosition={visual?.imagePosition}
          bgColor={visual?.bgColor}
        />
      ))}
      <div className="integration-bg text-white">
        <Integration background="dark"/>
      </div>
      <Efficiency/>

      <Footer pathname={'visual-feedback'} />
    </Wrapper>
  );
};

export default VisualFeedback;
