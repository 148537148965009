import React from 'react';
import BugsSection from './BugsSection';
import StartForFree from './StartForFree';
import { Container } from 'react-bootstrap';

const Feature = ({
  img,
  headerText,
  bulletPoints,
  imagePosition,
  bgColor,
}: {
  img: string;
  headerText: string;
  bulletPoints: string[];
  imagePosition: 'right' | 'left';
  bgColor: '#131A36' | '#FFFFFF';
}) => {
  return (
    <div style={{ backgroundColor: bgColor }}>
      <Container>
        <div className="py-4 py-lg-5">
          <div
            className={`d-flex flex-md-row flex-column justify-content-between ${imagePosition === 'right' ? 'flex-md-row-reverse text-white' : 'base-color '}`}
          >
            <div className="d-md-none fnt-sm-16 fnt-24  fw-600 mb-3">{headerText}</div>
            <div className="col-md-5 me-3 mb-3 mb-md-0">
              <img
                src={img}
                className="img-fluid text-center"
                alt="extension"
              />
            </div>

            <div className="col-md-6 d-flex flex-column align-items-lg-start">
              <div className="fnt-sm-16 d-none d-md-block fnt-24 fw-600 mb-3">{headerText}</div>
              <div className="fw-400 fnt-sm-14 fnt-18 mb-3">
                <ul className="ms-4">
                  {bulletPoints?.map((bulletPoint: string) => (
                    <li key={bulletPoint} className="mb-2">
                      {bulletPoint}
                    </li>
                  ))}
                </ul>
              </div>
              <StartForFree buttonPostion={bgColor} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Feature;
